<template>
  <b-card-actions action-collapse title="List Alamat">
    <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="12" sm="12" class="my-1" v-if="items.length == 0" >
        <!-- <b-button @click.prevent="add" variant="primary">{{ $t('Add') }}
        </b-button> -->

        <b-button variant="outline-primary" @click.prevent="$router.push('/akun-pengguna-tambah-alamat-retur')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                {{$t('Add')}} Alamat Baru
              </b-button>
        <b-modal v-model="showModalAdd" id="modal-tambah" cancel-variant="secondary" ok-title="Add"
          cancel-title="Batal" centered title="Alamat Baru">
          <b-form>
            <b-row>
              <b-col md="6" sm="12">
                <b-form-group>
                  <label for="nama_kontak">Nama Lengkap</label>
                  <b-form-input v-model="form.nama_kontak" id="nama_kontak" type="text" placeholder="Nama Lengkap" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group>
                  <label for="nomor_kontak">No. Telepon (Aktif)</label>
                  <b-form-input v-model="form.nomor_kontak" id="nomor_kontak" type="text" placeholder="Nomor Telepon" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group>
                  <label for="label_alamat">Label Alamat</label>
                  <b-form-input v-model="form.label_alamat" id="label_alamat" type="text"
                    placeholder="Rumah, Kantor, Apartemen" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group>
                  <label for="jenis">Jenis Alamat</label>
                  <b-form-radio v-model="form.jenis_alamat" name="jenis_alamat"
                    value="utama" disabled>
                    Utama
                  </b-form-radio>
                  <b-form-radio v-model="form.jenis_alamat" name="jenis_alamat" value="toko" disabled>
                    Toko
                  </b-form-radio>
                  <b-form-radio v-model="form.jenis_alamat" name="jenis_alamat" value="retur">
                    Retur
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Provinsi" label-for="v-provinsi">
                  <v-select v-model="form.provinsi_id" :reduce="(option) => option.value" label="text"
                    :options="optprovinsi" @input="getkabkota()" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Kabupaten/Kota" label-for="v-kabko">
                  <v-select v-model="form.kab_kota_id" :reduce="(option) => option.value" label="text"
                    :options="optkabko" @input="getkecamatan()" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Kecamatan" label-for="v-kecamatan">
                  <v-select v-model="form.kecamatan_id" :reduce="(option) => option.value" label="text"
                    :options="optkec" @input="getkelurahan()" />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Kelurahan/Desa" label-for="v-kel">
                  <v-select v-model="form.desa_id" :reduce="(option) => option.value" label="text" :options="optkel" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group>
                  <label for="alamat">Alamat</label>
                  <b-form-textarea v-model="form.alamat" id="alamat" placeholder="Nama Jalan, Gang, Gedung, No Rumah" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group>
                  <label for="reward">Catatan</label>
                  <b-form-textarea v-model="form.catatan" id="deskripsi"
                    placeholder="Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <!-- <b-form-group> -->
                  <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng">
        <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
          icon-url="/assets/gps.png"
        />
        <l-popup>You're here!</l-popup>
      </l-marker>
    </l-map>
    
                  <!-- </b-form-group> -->
              </b-col>
            </b-row>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Simpan </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <!-- <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search"  />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group> 
      </b-col>-->

      <b-col cols="12">
        <template v-for="item in items">
          <b-card border-variant="primary">
            <b-row>
              <b-col lg="9" md="9" sm="12">
                <b-card-title><b>{{ item.nama_kontak }}</b> | {{ item.nomor_kontak }}</b-card-title>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-button variant="info" class="ml-1" size="sm" @click.prevent="$router.push(`/akun-pengguna-edit-alamat-retur/${item.id}`)"> Edit Alamat
                </b-button>
                <b-button variant="danger" class="ml-1" size="sm" @click="remove(item)"> Hapus
                </b-button>
                <!-- <hr /> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card-text>
                  {{ item.alamat }}
                </b-card-text>
                <b-card-text>
                  {{ item.desa ? item.desa.name + "," : null }}
                  {{ item.kecamatan ? item.kecamatan.name + "," : null }}
                  {{ item.kab_kota ? item.kab_kota.name + "," : null }}
                  {{ item.provinsi ? item.provinsi.name : null }}
                </b-card-text>
                <b-card-text>
                  Catatan : {{ item.catatan ? item.catatan : null }}
                </b-card-text>
                <b-card-text>
                  <h4><b-badge variant="primary">{{ item.jenis_alamat.toUpperCase() }}</b-badge></h4>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </template>
        
        <!-- <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(detail)="{ item }">
            <b-card>
            <b-card-title>{{ item.nama_kontak }} | {{item.nomor_kontak}}</b-card-title>
          <b-card-text>
            {{ item.alamat }}
          </b-card-text>
          </b-card>
          </template>
          <template #cell(actions)="row">
            <b-button title="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button title="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1"
              variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table> -->
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col> -->
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LPopup, LIcon,
} from 'vue2-leaflet'
// import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng, icon } from "leaflet";
import 'leaflet/dist/leaflet.css'
import vSelect from "vue-select";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTable, BCard, BCardTitle, BCardText, BFormTextarea, BFormRadio, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton } from "bootstrap-vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    vSelect, BCard, BCardTitle, BCardText,
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect, BFormTextarea, BFormRadio,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 10,
      center: [47.313220, -1.319482],
      markerLatLng: [47.313220, -1.319482],
      icon: icon({
        iconUrl: "/assets/gps.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      optprovinsi: [],
      optkabko: [],
      optkec: [],
      optkel: [],
      showModalAdd: false,
      showModalEdit: false,
      id: null,
      form: {
        label_alamat: null, // OPTIONAL
        provinsi_id: null,
        latitude: null,  // OPTIONAL
        longitude: null,  // OPTIONAL
        kab_kota_id: null,
        kecamatan_id: null,
        desa_id: null, // OPTIONAL
        alamat: null,
        nomor_kontak: null,
        nama_kontak: null,
        jenis_alamat: 'retur', // PILIHAN utama toko retur
        catatan: null, // OPTIONAL
      },
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "detail", label: "Detail Alamat", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
    // this.getProvinsi()
  },
  methods: {
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalAdd = true;
      this.showModalEdit = false;
    },
    ubah(item) {
      this.activeAction = "ubah";
      this.form = item
      this.getkabkota();
      this.getkecamatan();
      this.getkelurahan();
      // this.form.kab_kota_id= item.kab_kota_id ? { text : item.kab_kota.name, value : item.kab_kota_id} null;
      //   this.form.kecamatan_id= null;
      //   this.form.desa_id= null; /
      this.showModalAdd = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = new FormData();
          payload.append("id", item.id);
          payload.append("fungsi", 1);
          this.$store
            .dispatch("alamat/savedelete", payload)
            .then(() => {
              // this.pesanBerhasilHapus();
              // this.resetForm();
              this.getData();
              this.displaySuccess({
                message: 'Berhasil hapus alamat'
              })
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        label_alamat: null, // OPTIONAL
        provinsi_id: null,
        latitude: null,  // OPTIONAL
        longitude: null,  // OPTIONAL
        kab_kota_id: null,
        kecamatan_id: null,
        desa_id: null, // OPTIONAL
        alamat: null,
        nomor_kontak: null,
        nama_kontak: null,
        jenis_alamat: null, // PILIHAN utama toko retur
        catatan: null, // OPTIONAL
      };
      this.showModalAdd = false;
      this.showModalEdit = false;
    },
    submit() {
      if (this.form.nama_kontak == null || this.form.nama_kontak == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if (this.id) {
        this.form.id = this.id
      }
      const payload = this.form
      payload.member_id = this.user.member_id

      this.$store
        .dispatch("alamat/save", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.showModalAdd = false;
          this.showModalEdit = false;
          this.getData();
          this.resetForm()
          this.displaySuccess({
            text: "Alamat berhasil diperbarui !",
          });
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      this.id = item.id;
      console.log(item);
      this.form = {
        nama: item.nama,
        no_rekening: item.no_rekening,
        deskripsi: item.deskripsi,
        rekening_utama: item.rekening_utama == 1 ? true : false,
      };
      this.showModalEdit = true;
    },
    async getProvinsi() {
      let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
      provinsi = JSON.parse(JSON.stringify(provinsi));
      let newprovinsi = [];
      provinsi.map((item) => {
        newprovinsi.push({
          text: item.name,
          value: item.id,
        });
      });
      // this.id_provinsi = newprovinsi;
      this.optprovinsi = newprovinsi;
      // });
    },
    async getkabkota() {
      const params = {
        id_provinsi: this.form.provinsi_id ? this.form.provinsi_id : null
      }
      let kabko = await this.$store.dispatch("wilayah/getkabko", params);
      kabko = JSON.parse(JSON.stringify(kabko));
      let newkabko = [];
      kabko.map((item) => {
        newkabko.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkabko = newkabko;
    },
    async getkecamatan() {
      const params = {
        id_kab_kota: this.form.kab_kota_id ? this.form.kab_kota_id : null
      }
      let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
      kec = JSON.parse(JSON.stringify(kec));
      let newkec = [];
      kec.map((item) => {
        newkec.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkec = newkec;
    },
    async getkelurahan() {
      const params = {
        id_kecamatan: this.form.kecamatan_id
      }
      let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
      kel = JSON.parse(JSON.stringify(kel));
      let newkel = [];
      kel.map((item) => {
        newkel.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkel = newkel;
    },
    getData() {
      if (this.isCabang || this.isMarketing) {
        var params = {
          member_id: this.user.member_id,
          jenis_alamat: 'retur',
        };
      } else {
        var params = {};
      }
      this.$store
        .dispatch("alamat/getData", params)
        .then(() => {
          this.items = this.$store.state.alamat.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<!-- <style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style> -->
<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>